/**
 * Copyright D3R 2013
 * http://d3r.com
 * dom@d3r.com
 *
 * Dependencies: jQuery, jquery.widget.js, jquery.mobile.events.js
 */
(function($) {
    var slider = {
        options: {
            item            : ".grid > *",
            page            : false,
            wrapper         : ".grid",
            next            : ".slider__next",
            prev            : ".slider__prev",
            count           : ".slider__count",
            index           : ".slider__index",
            nav             : ".slider__nav",
            navItem         : ".slider__nav__item",
            navActiveClass  : "slider__nav__item--active",
            autoplay        : false,
            autostop        : false,
            autodelay       : 5000,
            equalHeights    : false,
            debug           : false,
            removeClasses   : false,
            disableSelect   : true,
            onInitCallback  : $.noop,
            onSelectCallback: $.noop
        },
        _init: function() {
            var widget = this;
            this._debug('Slider :: INIT');

            this.slides = $(this.options.item, this.element);
            this._debug(this.slides);
            if (!!this.options.page) {
                this.itemsPerPage = 0;
                this.wrapper = $(this.options.wrapper, this.element);
                this._setupPages();
            }

            if (this.slides.length > 1) {
                this.element.addClass('slider--enabled');

                this.slides.addClass('slider__slide');

                this._debug('Slider :: INIT', this.slides);

                var prevent = ['dragstart'];

                if (this.options.disableSelect) {
                    prevent.push('selectstart');
                }

                $(this.element).bind(prevent.join(' '), function(e) {
                    e.preventDefault();
                });

                var active = this.slides.filter('.slider__slide--active');

                if (active.length) {
                    this.active = active.eq(0);
                } else {
                    this.active = this.slides.eq(0).addClass('slider__slide--active');
                }

                this.index = this.slides.index(this.active);

                this.playing = false;

                if (!!this.options.next) {
                    $(this.options.next, this.element).bind('click', $.proxy(this, 'next'));
                }
                if (!!this.options.prev) {
                    $(this.options.prev, this.element).bind('click', $.proxy(this, 'prev'));
                }

                if (!!this.options.nav) {
                    this._initNav();
                    if (!!this.options.count) {
                        $(this.options.count, this.element).text(this.slides.length);
                    }
                    this._updateNav();
                }

                // delay this because we need to wait for font replacement
                if (this.options.equalHeights) {
                    this._updateHeights();
                }

                // Unbind the swipes when not scrolling as jquery mobile doesn;t seem to be capable of not firing them.
                // $(window).bind('scrollstart', $.proxy(this, '_unbindSwipes')).bind('scrollstop', $.proxy(this, '_bindSwipes'));

                this._bindSwipes();

                if (this.options.autoplay) {
                    this.play();
                    this.element.mouseleave($.proxy(this, 'play'));
                    if (this.options.autostop) {
                        this.element.mouseenter($.proxy(this, 'stop'));
                    }
                }

                $(window).on('blur', function(e) {
                    if (widget.playing) {
                        $(window).on('blur', $.proxy(this, 'play'));
                    }
                    $.proxy(this, 'stop');
                });

                widget.options.onInitCallback();
            }
        },
        destroy: function() {
            this._debug('Slider :: DESTROY');

            if (this.options.removeClasses) {
                this.element.removeClass('slider--enabled');
                this.slides.removeClass(function(index, classnames) {
                    return (classnames.match (/\bslider\S+/g) || []).join(' ');
                });
            }

            this._unbindSwipes();

            $(this.options.next, this.element).unbind('click', $.proxy(this, 'next'));
            $(this.options.prev, this.element).unbind('click', $.proxy(this, 'prev'));
        },
        select: function(index, diff) {
            this._debug('Slider :: SELECT', index);
            if (this.slides.length <= index || this.index === index) return;

            if (!diff) diff = index - this.index;

            var widget = this,
                next = this.slides.eq(index),
                exitClass,
                enterClass;

            this._trigger('beforeSlide', null, {index: index, slide: next});

            if (diff >= 0) {
                // from the right
                exitClass = 'slider__slide--exit-left';
                enterClass = 'slider__slide--enter-right';
            } else {
                // from the left
                exitClass = 'slider__slide--exit-right';
                enterClass = 'slider__slide--enter-left';
            }

            widget.slides.removeClass('slider__slide--enter-left slider__slide--enter-right slider__slide--exit-left slider__slide--exit-right');
            next.addClass(enterClass);
            widget.active.addClass(exitClass);

            // Need the classes to be added in a separate moment
            window.setTimeout(function() {
                widget.active.removeClass('slider__slide--active');
                next.addClass('slider__slide--active');
                widget.active = next;
                widget.index = index;
                if (!!widget.options.nav) {
                    widget._updateNav();
                }
                widget._trigger('afterSlide', null, {index: index, slide: next});
                widget.options.onSelectCallback();
            }, 50);
        },
        next: function(e) {
            e.preventDefault();
            this.stop();
            this._next();
        },
        prev: function(e) {
            e.preventDefault();
            this.stop();
            this._prev();
        },
        _next: function() {
            this.select((this.index + 1) % this.slides.length, 1);
        },
        _prev: function() {
            this.select((this.index + this.slides.length - 1) % this.slides.length, -1);
        },
        play: function() {
            this._debug('Slider :: PLAY');
            if (!this.playing) this.playing = setInterval($.proxy(this, '_next'), this.options.autodelay);
        },
        stop: function() {
            this._debug('Slider :: STOP');
            if (this.playing) {
                clearInterval(this.playing);
                this.playing = false;
            }
        },
        _setupPages: function() {
            this._debug('Slider :: SETUP PAGES');
            var count = Math.round(this.wrapper.innerWidth() / this.slides.outerWidth());
            if (count !== this.itemsPerPage && this.itemsPerPage < this.slides.length) {
                var page = $(this.options.page,this.element).first();
                this.itemsPerPage = count;
                var items = this.slides.toArray();
                this.wrapper.empty();
                while (items.length > 0) {
                    this.wrapper.append(page.clone().append(items.splice(0, count)).addClass('slider__slide'));
                }
                this.slides = this.wrapper.children();
                // $(window).bind('resize', $.proxy(this, '_setupPages'));
            }
        },
        _bindSwipes: function() {
            this._debug('Slider :: BIND SWIPES');
            this.slides.bind('swipeleft', $.proxy(this, 'next'));
            this.slides.bind('swiperight', $.proxy(this, 'prev'));
        },
        _unbindSwipes: function() {
            this._debug('Slider :: UNBIND SWIPES');
            this.slides.unbind('swipeleft', $.proxy(this, 'next'));
            this.slides.unbind('swiperight', $.proxy(this, 'prev'));
        },
        _initNav: function() {
            this._debug('Slider :: INIT NAV');
            var widget = this;
            if (!!this.options.nav) {
                this.nav = $(this.options.nav, this.element);
                this.navItems = $(widget.options.navItem, this.nav);
                this.nav.on('click', widget.options.navItem, function(e) {
                    e.preventDefault();
                    var index = $(this).index();
                    if (index !== widget.index) {
                        widget.stop();
                        widget.select(index);
                    }
                });
            }
        },
        _updateNav: function() {
            if (!!this.options.index) {
                $(this.options.index, this.element).text(this.index + 1);
            }
            if (!!this.options.nav) {
                this.navItems.removeClass(this.options.navActiveClass);
                this.navItems.eq(this.index).addClass(this.options.navActiveClass);
            }
        },
        _updateHeights: function() {
            this._debug('Slider :: UPDATE HEIGHTS', this.element.height());
            this.slides.css('height', 'auto');
            this.slides.css('height', this.element.innerHeight());
        },
        _debug: function() {
            if (true === this.options.debug && !!window.console && !!window.console.log){
                window.console.log.apply(window.console, arguments);
            }
        }
    };
    $.widget('d3r.slider', slider);
})(jQuery);
