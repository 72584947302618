/*
 * Copyright 2014 D3R Ltd
 * http://d3r.com
 *
 * D3R Standard Javascript
 *
 * You'll want at least:

    .js .d3r-show,
    .js .d3r-hide {
        cursor: pointer;
    }
    .js .d3r-hidden {
        display: none;
    }
    .js .d3r-shown {
        display: block;
    }
    .d3r-chooser {

    }
        .d3r-chooser-option,
        .d3r-multichooser-option {
            display: none;
        }
 *
 */

// hidey showey
$(document).on('click', '.d3r-show, .d3r-hide', function(e) {
    var $this = $(this),
        $target;
    if ($this.is('a')) e.preventDefault();
    if (!!$this.data('target')) {
        $target = $('#'+$this.data('target'));
    } else if (!!$this.attr('href') && "#" == $this.attr('href')[0]) {
        $target = $($this.attr('href'));
    }
    if ($target.length) {
        var hide = $this.hasClass('d3r-hide');
        $target[hide ? 'toggleClass' : 'addClass']('d3r-shown');
        $this[hide ? 'toggleClass' : 'addClass']('d3r-active');
    }
    if ($target.hasClass('d3r-shown') && $this.hasClass('d3r-autoclose')) {
        var autoclose = function(e) {
            if (!$.contains($target[0], e.target)) {
                if ($target.hasClass('d3r-shown')) {
                    e.preventDefault();
                    $target.removeClass('d3r-shown');
                    $this.removeClass('d3r-active');
                }
                $(document).off('click', autoclose);
            }
        };
        $(document).on('click', autoclose);
    }
});
// redirect from dropdowns
$(document).on('change', 'select.d3r-redirector', function(e) {
    window.location.assign($(this).val());
});
// display things based on dropdowns
$(document).on('change', 'select.d3r-chooser', function(e) {
    var $this = $(this),
        $options = $('.d3r-chooser-option').filter('[data-chooser="'+$this.attr('id')+'"]');
    $options.removeClass('d3r-shown');

    var selectors = ['[data-chooservalue="' + $this.val() + '"]'];

    if ($this.val()) {
        selectors.push('#' + $this.val());
    }

    $options.filter(selectors.join(',')).addClass('d3r-shown');
});
$(document).on('change', 'select.d3r-multichooser', function(e) {
    var $this = $(this),
        $options = $('.d3r-multichooser-option').filter('[data-multichooser="'+$this.attr('id')+'"]'),
        $option = $('option:selected', this);
    $options.removeClass('d3r-shown');
    $.each($option.data('values').split(' '), function(index, val) {
        var selectors = ['[data-chooservalue="' + val + '"]'];

        if (val) {
            selectors.push('#' + val);
        }

        $options.filter(selectors.join(',')).addClass('d3r-shown');
    });
});
$(document).on('change', 'input[type="radio"].d3r-chooser', function(e) {
    var $this = $(this),
        $options = $('.d3r-chooser-option').filter('[data-chooser="'+$this.attr('name')+'"]');
    $options.removeClass('d3r-shown');

    if ($this.is(':checked')) {
        var selectors = ['[data-chooservalue="' + $this.val() + '"]'];

        if ($this.val()) {
            selectors.push('#' + $this.val());
        }

        $options.filter(selectors.join(',')).addClass('d3r-shown');
    }
});

$('input[type="radio"].d3r-chooser:checked').trigger('change');

$(document).on('click', '.d3r-submit', function(e) {
    var $this = $(this);

    // If the form is invalid, don't disable the button.
    if ($this.closest('form') &&
        !$this.closest('form')[0].checkValidity()) {
        return;
    }
    // The timeout is to allow this submission to proceed. Nasty I know, sorry.
    if ($this.hasClass('d3r-disabled')) {
        e.preventDefault();
    }
    $this.addClass('d3r-disabled');
    if (!$this.is('a')) {
        setTimeout(function() {
            $this.prop('disabled', true);
        }, 0);
    }
});
$(document).on('click', '.d3r-confirm', function(e) {
    var $this = $(this),
        message = 'Are you sure?';
    if (!!$this.data('confirm')) message = $this.data('confirm');
    if (!window.confirm(message)) e.preventDefault();
});
$(document).on('click', '.d3r-scrollto', function(e) {
    var $this = $(this),
        $target;
    if (!!$this.data('target')) {
        $target = $('#'+$this.data('target'));
    } else if (!!$this.attr('href') && "#" == $this.attr('href').replace(window.location.pathname,"")[0]) {
        $target = $($this.attr('href').replace(window.location.pathname,""));
    }
    if ($target.length) {
        var hash = $target.attr("id");
        $target.removeAttr("id");
        $("html,body").animate({
            scrollTop: $target.offset().top
        }, 500, function() {
            $target.attr("id", hash);
        });
    }
});
$(document).on('click', '.d3r-popup', function(e) {
    e.preventDefault();
    var $this = $(this),
        url = $this.attr('href'),
        width = 600,
        height = 300,
        target = 'popup';
    if (!!$this.data('width')) width = $this.data('width');
    if (!!$this.data('height')) height = $this.data('height');
    if (!!$this.attr('target')) target = $this.attr('target');

    var newwindow = window.open(url, target ,'height='+height+',width='+width);
    if (window.focus) newwindow.focus();
});
