/**
 * Copyright D3R 2013
 * http://d3r.com
 * dom@d3r.com
 *
 * Dependencies: jQuery, jquery.widget.js, jquery.mobile.events.js
 */
(function($) {
    var scrollable = {
        options: {
            track            : ".scrollable__track",
            slide            : ".scrollable__slide",
            next            : ".scrollable__next",
            prev            : ".scrollable__prev",
            count           : ".scrollable__count",
            index           : ".scrollable__index",
            nav             : ".scrollable__nav",
            navItem         : ".scrollable__nav__item",
            navActiveClass  : "scrollable__nav__item--active",
            disabledClass   : "scrollable--disabled",
            activeClass     : "scrollable__slide--active",
            hiddenClass     : "scrollable__slide--hidden",
            paginate        : false,
            autorefresh     : true,
            autoplay        : false,
            autostop        : false,
            autodelay       : 5000,
            debug           : false,
            beforeSlide     : false,
            afterSlide      : false
        },
        _init: function() {
            this._debug('Scrollable :: INIT');

            var widget = this;

            this.slides = $(this.options.slide, this.element);
            this.track = $(this.options.track, this.element);

            this._debug(this.slides);

            if (!!this.options.page) {
                this.itemsPerPage = 0;
                this.wrapper = $(this.options.wrapper, this.element);
                this._setupPages();
            }

            if (this.slides.length > 1) {

                this._debug('Scrollable :: INIT', this.slides);

                $(this.element).bind('dragstart selectstart', function(e) {
                    e.preventDefault();
                });

                this.active = this.slides.eq(0);

                this.index = this.slides.index(this.active);

                if (this.options.paginate) {
                    this.perPage = Math.round(this.track.outerWidth() / this.active.outerWidth());
                }

                this.playing = false;

                if (!!this.options.next) {
                    this.nextButton = $(this.options.next, this.element).on('click', $.proxy(this, 'next'));
                }
                if (!!this.options.prev) {
                    this.prevButton = $(this.options.prev, this.element).on('click', $.proxy(this, 'prev'));
                }

                if (!!this.options.nav) {
                    this._initNav();
                    if (!!this.options.count) {
                        $(this.options.count, this.element).text(this.slides.length);
                    }
                    this._updateNav();
                }

                if (this.options.autorefresh) {
                    $(window).resize($.proxy(this, 'refresh'));
                }

                // Unbind the swipes when not scrolling as jquery mobile doesn;t seem to be capable of not firing them.
                // $(window).bind('scrollstart', $.proxy(this, '_unbindSwipes')).bind('scrollstop', $.proxy(this, '_bindSwipes'));

                this._bindSwipes();
                this._updatePages();
                this._updateButtons();
                this._updateHidden();
                this._updateActive();

                if (this.options.autoplay) {
                    this.play();
                    this.element.mouseleave($.proxy(this, 'play'));
                    if (this.options.autostop) {
                        this.element.mouseenter($.proxy(this, 'stop'));
                    }
                }

                // This is a hack to disable transitions of elements on page load
                window.setTimeout(function() {
                    widget.element.addClass('scrollable--enabled');
                }, 1);
            }
        },
        destroy: function() {
            this._debug('Scrollable :: DESTROY');

            if (!!this.options.next) {
                $(this.options.next, this.element).off('click', $.proxy(this, 'next'));
            }
            if (!!this.options.prev) {
                $(this.options.prev, this.element).off('click', $.proxy(this, 'prev'));
            }
            if (this.options.autorefresh) {
                $(window).off('resize', $.proxy(this, 'refresh'));
            }
        },
        select: function(index) {
            this._debug('Scrollable :: SELECT', index);
            if (!this._indexIsValid(index)) return;
            // this._trigger('beforeSlide', null, {index: index, slide: next});

            this.active = this.slides.eq(index);
            this.index = index;
            var widget = this;

            $.when($.isFunction(this.options.beforeSlide) && this.options.beforeSlide.call(widget)).done(function() {
                widget.refresh();
                widget._updateNav();
                widget._updateHidden();
                widget._updateActive();
                if ($.isFunction(widget.options.afterSlide)) {
                    widget.options.afterSlide.call(widget)
                }
                if (widget.options.autoplay) {
                    widget.play();
                }
            });
        },
        refresh: function() {
            this._debug('Scrollable :: REFRESH');
            var multiplier = this.active.outerWidth() / this.track.outerWidth();
            this.track.css({
                transform: "translateX(-" + (this.index * 100 * multiplier) + "%)"
            });
            this._updatePages();
            this._updateButtons();
        },
        next: function(e) {
            if (!!e) e.preventDefault();
            this.stop();
            this.select(this._nextIndex());
        },
        _nextOrStart: function() {
            if (this._indexIsValid(this._nextIndex())) {
                this.next();
            } else {
                this.select(0);
            }
        },
        _nextIndex: function() {
            var index = this.index + 1;
            if (this.options.paginate) {
                index = this.index + this.itemsPerPage;
            }
            return index;
        },
        prev: function(e) {
            if (!!e) e.preventDefault();
            this.stop();
            this.select(this._prevIndex());
        },
        _prevIndex: function() {
            var index = this.index - 1;
            if (this.options.paginate) {
                index = this.index - this.itemsPerPage;

                if (index > 0 && index < this.itemsPerPage) {
                    index = 0;
                }
                if (index >= this.slides.length) {
                    index = this.slides.length - 1;
                }
            } else if (index > this.slides.length - this.itemsPerPage) {
                index = this.slides.length - this.itemsPerPage;
            }
            return index;
        },
        play: function() {
            this._debug('Scrollable :: PLAY');
            if (!this.playing) this.playing = setInterval($.proxy(this, '_nextOrStart'), this.options.autodelay);
        },
        stop: function() {
            this._debug('Scrollable :: STOP');
            if (this.playing) {
                clearInterval(this.playing);
                this.playing = false;
            }
        },
        _updatePages: function() {
            this._debug('Scrollable :: UPDATE PAGES');
            this.itemsPerPage = Math.round(this.track.innerWidth() / this.slides.outerWidth());
        },
        _updateHidden: function() {
            this.slides.removeClass(this.options.hiddenClass);
            if (this.index > 0) {
                this.slides.slice(0, this.index).addClass(this.options.hiddenClass);
            }
            this.slides.slice(this.index + this.itemsPerPage).addClass(this.options.hiddenClass);
        },
        _updateActive: function() {
            this.slides.removeClass(this.options.activeClass);
            this.active.addClass(this.options.activeClass);
        },
        _bindSwipes: function() {
            this._debug('Scrollable :: BIND SWIPES');
            this.element.bind('swipeleft', $.proxy(this, 'next'));
            this.element.bind('swiperight', $.proxy(this, 'prev'));
        },
        _unbindSwipes: function() {
            this._debug('Scrollable :: UNBIND SWIPES');
            this.element.bind('swipeleft', $.proxy(this, 'next'));
            this.element.bind('swiperight', $.proxy(this, 'prev'));
        },
        _updateButtons: function() {
            this.prevButton[this._indexIsValid(this._prevIndex()) ? 'removeClass' : 'addClass'](this.options.disabledClass);
            this.nextButton[this._indexIsValid(this._nextIndex()) ? 'removeClass' : 'addClass'](this.options.disabledClass);
            this.element[
                this._indexIsValid(this._nextIndex()) || this._indexIsValid(this._prevIndex())
                    ? 'removeClass'
                    : 'addClass'
                ]('scrollable--static');
        },
        _indexIsValid: function(index) {
            if (index < 0) return false;
            if (!this.options.paginate) return index <= (this.slides.length - this.itemsPerPage);
            return index + 1 <= this.slides.length;
        },
        _initNav: function() {
            this._debug('Scrollable :: INIT NAV');
            var widget = this;
            if (!!this.options.nav) {
                this.nav = $(this.options.nav, this.element);
                this.navItems = $(widget.options.navItem, this.nav);
                this.nav.on('click', widget.options.navItem, function(e) {
                    e.preventDefault();
                    var index = $(this).index();
                    if (index !== widget.index) {
                        widget.stop();
                        widget.select(index);
                    }
                });
            }
        },
        _updateNav: function() {
            if (!!this.options.index) {
                $(this.options.index, this.element).text(this.index + 1);
            }
            if (!!this.options.nav) {
                this.navItems.removeClass(this.options.navActiveClass);
                this.navItems.eq(this.index).addClass(this.options.navActiveClass);
            }
        },
        // _updateHeights: function() {
        //     this._debug('Scrollable :: UPDATE HEIGHTS', this.element.height());
        //     this.slides.css('height', 'auto');
        //     this.slides.css('height', this.element.innerHeight());
        // },
        _debug: function() {
            if (true === this.options.debug && !!window.console && !!window.console.log){
                window.console.log.apply(window.console, arguments);
            }
        }
    };
    $.widget('d3r.scrollable', scrollable);
})(jQuery);
